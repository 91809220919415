<template>
  <div class="card"> <!-- Basic information -->
    <div
      v-b-toggle="`collapseSection-8`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">Personal data protection contract</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a
              data-action="collapse"
            ><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content">
      <b-collapse
        :id="`collapseSection-8`"
      >
        <div class="card-content">
          <div class="card-header d-flex align-items-center justify-content-between">
            <div class="bg-light-secondary py-2 px-2">
              <h4>Variables</h4>
              <p>{{ labels['personal_data_protection_contract.description'] }}</p>
              <ul>
                <li><strong>[date] </strong>date</li>
                <li><strong>[prof] </strong>??</li>
                <li><strong>[card] </strong>national identity card/passport no</li>
                <li><strong>[address] </strong>whose address is </li>
                <li><strong>[email] </strong>email address </li>
                <li><strong>[authorised] </strong>If this publication is not authorised, tick the following box</li>
              </ul>
            </div>
          </div>
          <div class="card-body">
            <div class="mb-2">
              <label
                for=""
                class="form-label"
              >{{ labels['personal_data_protection_contract.text'] }}</label>
              <quill-editor
                v-model="convo.protection_contract"
                @blur="submitAnswer($event.root.innerHTML, 'protection_contract')"
              />
            </div>
            <h4 class="mb-2">
              Required for:
            </h4>
            <div
              v-for="role in roles"
              :key="role.id"
              class="form-check-success form-switch w-100 "
            >
              <input
                id="applicantsRow"
                type="checkbox"
                class="form-check-input"
                :checked="convo.protectionContractRoles ? convo.protectionContractRoles.filter(e => e == role.id).length > 0 : false"
                :value="role.id"
                @change="addRole($event)"
              >
              <label
                class="form-check-label ms-1"
                for="applicantsRow"
              > {{ role.name }}</label>
              <hr>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCollapse,
  },
  props: {
    type: { type: String, required: false, default: '' },
  },
  data() {
    return {
      basic: {},
      roles: [{ id: 28, name: 'Peers' }, { id: 29, name: 'PC Members' }, { id: 23, name: 'Moderators' }, { id: 24, name: 'Hosts' }],
      defaultText: `<p>Barcelona, on [date]</p>
                    <h3>BETWEEN</h3>
                    <p>Mr. Emilià Pola Robles, national identity card no. 46042576-A, acting for and on behalf of Institució Catalana de Recerca i Estudis Avançats (ICREA), whose address is Pg. Lluis Companys, 23 (08010) Barcelona with tax number G62515838 from herein ‘DATA CONTROLLER’.</p>
                    <p>and Prof. [prof], national identity card/passport no. [card], acting on his own behalf, as Assessor, whose address is [address] from herein ‘DATA PROCESSOR’.</p>
                    <h3>WHEREAS</h3>
                    <ol>
                      <li>The DATA CONTROLLER is the owner of the file containing personal data on its researcher employees and candidates participating in selection processes for appointments as researchers. </li>
                      <li>The DATA CONTROLLER commissions the following services from DATA PROCESSOR, who accepts:
                        <ul>
                          <li>Assessing candidates.</li>
                          <li>Assessing different research projects presented by any Catalan university or research centre in Catalonia and which is part of an institution with its own legal status.</li>
                          <li>Assessing, if necessary, the work undertaken by the DATA CONTROLLER’s researchers.</li>
                        </ul>
                      </li>
                      <li>In order to perform the contracted services, DATA PROCESSOR may access the personal data on the researchers and candidates participating in selection processes for appointments as researchers, included in the files owned by the DATA CONTROLLER</li>
                      <li>That the DATA PROCESSOR’s access to the DATA CONTROLLER’s personal data is subject to the legal provisions of EU REGULATION 2016/679 OF THE EUROPEAN PARLIAMENT AND COUNCIL of 27 April, 2016 on the protection of natural persons with regard to the processing of personal data and the free movement of these data and which repeals Directive 95/46 / EC (General Data Protection Regulation, hereinafter ‘GDPR’), as well as Organic Law 3/2018, of 5 December, on the protection of personal data and guarantee of digital rights (hereinaf-ter ‘LOPDGDD’), as well as any other regulations that in a future may be enacted on this matter and replace or complement them.</li>
                    </ol>
                    <h3>CLAUSES</h3>
                    <h4>ONE.- PURPOSE</h4>
                    <p>The purpose of this contract is to define the conditions by which DATA PROCESSOR will process the personal data to which he has access while providing the contracted services. DATA PROCESSOR agrees to process the abovementioned personal data with due diligence and in accordance with their best practices and professionalism.</p>
                    <p>......</p>`,
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      // roles: 'roles/allRoles',
      labels: 'sectionLabels/itemLabels',
    }),
  },
  async mounted() {
    // await this.$store.dispatch('roles/fetchAll')
    if (!this.convo.protection_contract) {
      this.convo.protection_contract = this.defaultText
      this.submitAnswer(this.defaultText, 'protection_contract')
      this.$forceUpdate()
    }
  },
  methods: {
    submitAnswer(data, type) {
      this.basic[type] = data
      this.$emit('saveAnswer', { id: 'contract', data: this.basic })
    },
    addRole(event) {
      if (event.target.checked) {
        this.convo.protectionContractRoles.push(event.target.value)
      } else {
        this.convo.protectionContractRoles.splice(this.convo.protectionContractRoles.findIndex(x => x === event.target.value), 1)
      }

      this.$emit('saveAnswer', { id: 'contract_roles', data: this.convo.protectionContractRoles })
    },
  },
}
</script>
